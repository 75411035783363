@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.root
  display: flex
  align-items: center
  justify-content: space-between
  font-size: .875rem
  font-weight: 700

@include media('>Mphone')
  .root
    font-size: 1rem
