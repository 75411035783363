@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.root
  display: flex
  justify-content: space-between
  width: 100%
  max-width: 50rem
  margin-bottom: 4rem

.col
  display: none
  flex-direction: column
  width: 100%
  max-width: 18.125rem
  margin: 0 auto

.show
  display: flex

.switcher
  margin: 0 auto 2rem

@include media('>Lphone')
  .col
    max-width: initial

@include media('>Mdesktop')
  .root
    max-width: initial
  .col
    display: flex
    width: initial
