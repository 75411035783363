@import "styles/variables.sass"
@import "styles/breakpoints.scss"

.nav
  font-size: 1rem
  font-weight: 700
  transition: color $animationTime
  text-transform: uppercase
  &:hover
    color: $light-yellow
  &.active, &:active
    color: $blue-neutral

@include media('>Ltablet')
  .nav
    font-size: .875rem
