@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.root
  display: flex
  flex-direction: column

.tokenWrapper
  display: flex
  flex-direction: column
  margin-bottom: 2rem

.token
  display: flex
  align-items: center

.logo
  margin-right: 2rem

.name, .price
  text-shadow: $white-shadow
  font-size: 1.5rem
  font-weight: 700

.loadingContainer
  height: 2rem

.name
  width: 100%
  margin-bottom: 0.5rem

.price
  margin-left: 5rem
  color: $white

.priceNumber
  path
    fill: $white

.tablePreloader
  width: 100%
  height: 11.3rem

@include media('>Mphone')
  .tokenWrapper
    flex-direction: row
    align-items: center
    justify-content: space-between
  .loadingContainer
    width: initial
    height: initial
  .name
    margin-bottom: 0
    text-shadow: $white-shadow-button
  .price
    margin-left: 0
    color: $light-blue
    text-shadow: $blue-shadow-button
  .priceNumber
    path
      fill: $light-blue

@include media('>Lphone')
  .name, .price
    font-size: 2rem
  .tablePreloader
    width: 100%
    height: 6rem

@include media('>Ltablet')
  .name
    font-size: 3rem
  .price
    margin-right: 7rem
    font-size: 3rem
