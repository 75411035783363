@import 'styles/breakpoints'

.root
  max-width: 18.125rem
  margin: 0 auto 2rem

@include media('>Lphone')
  .root
    max-width: initial
    margin-bottom: 4rem
