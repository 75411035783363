@import 'styles/variables.sass'

.root
  display: flex
  align-items: center

.primary
  color: $light-blue
  text-shadow: $blue-shadow
  .attention
    &:hover
      .attentionIcon
        > path
          fill: $light-blue
    &:active
      .attentionIcon
        > path
          fill: $light-yellow

.secondary
  color: $light-yellow
  text-shadow: $light-yellow-shadow
  .attention
    &:hover
      .attentionIcon
        > path
          fill: $light-yellow
    &:active
      .attentionIcon
        > path
          fill: $light-blue

.attentionIcon
  > path
    transition: fill $animationTime

.attention
  margin-left: .25rem
  cursor: pointer
  &.attentionIconSmall
    margin-left: 0
