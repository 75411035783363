@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.toastBody
  width: 90%
  padding: 0
  word-break: break-word
  left: 50%
  transform: translateX(-50%)
  top: 4rem

.notification
  padding: .75rem 1rem
  font-size: .875rem
  background-color: rgba($dark-blue, .9)
  border-radius: .25rem
  text-align: center
  &:first-child
    margin-top: .5rem
  &:not(:last-child)
    margin-bottom: .5rem

.info
  color: $white
  border: .063rem solid $white

.error
  color: $red
  border: .063rem solid $red

.icon, .iconWrapper
  display: none
  margin-left: 1.375rem

@include media('>phone')
  .toastBody
    width: 24rem

@include media('>Mphone')
  .notification
    padding: 1rem 1.5rem
  .info, .error
    width: 100%
  .icon
    display: block
    position: absolute
    width: .75rem
    height: .75rem
    right: .875rem
    top: 50%
    transform: translateY(-50%)
  .iconWrapper
    display: block
  .toastBody
    left: inherit
    top: inherit
    transform: translateX(0)
