// Fonts
$font: "Rubik", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

// Colors
$main: #000205
$white: #FFFFFF
$white-80: rgba(#FFFFFF, .8)
$white-50: rgba(#FFFFFF, .5)
$black: #000000
$light-blue: #00E0FF
$blue-neutral: #00CDEE
$blue-neutral-30: rgba($blue-neutral, .3)
$blue: #00D1FF
$saturated-blue: #004F7B
$deep-blue: #0D1F39
$dark-blue: #001B33
$graphite: #0B1524
$dark-brown: #1E1600
$light-yellow: #FFC700
$dark-yellow: #FFB800
$saturated-yellow: #7B6000
$red: #D94100
$light-red: #FC4C00
$grey: #727272

// Gradients
$main-background: linear-gradient(180deg, $deep-blue 0%, $main 100%)
$yellow-light-gradient: linear-gradient(0deg, rgba(255, 199, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)
$blue-light-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 209, 255, 0.3) 100%)
$white-preloader-gradient: linear-gradient(270deg, rgba($white, 0.01) 0%, rgba($white, 0.32) 50.52%, rgba($white, 0.01) 100%)
$blue-preloader-gradient: linear-gradient(270deg, rgba(0, 205, 238, 0.01) 0%, rgba(0, 205, 238, 0.32) 50.52%, rgba(0, 205, 238, 0.01) 100%)
$yellow-preloader-gradient: linear-gradient(270deg, rgba(255, 199, 0, 0.01) 0%, rgba(255, 199, 0, 0.32) 50.52%, rgba(255, 199, 0, 0.01) 100%)

// Box-shadows
$blue-shadow: 0px 0px 2px $blue
$light-yellow-shadow: 0px 0px 2px $light-yellow
$white-shadow: 0px 0px 2px $white
$red-shadow: 0px 0px 4px $light-red
$white-shadow-button: 0px 0px 4px $white
$blue-shadow-button: 0px 0px 4px $blue
$light-yellow-shadow-button: 0px 0px 4px $light-yellow
$red-shadow: 0px 0px 2px $red
$grey-shadow: 0px 0px 2px #727272

// Timing
$animationTime: .25s
