@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.root
  position: relative
  object-fit: contain
  border-radius: 50%
  overflow: hidden

.small
  height: 1.5rem
  width: 1.5rem
  min-width: 1.5rem

.medium
  height: 2rem
  width: 2rem
  min-width: 2rem

.large
  height: 3rem
  width: 3rem
  min-width: 3rem

.primary
  background-color: rgba($blue-neutral, .4)

.secondary
  background-color: rgba($light-yellow, .4)

.tertiary
  background-color: rgba($white, .4)

@include media('>Mphone')
  .large
    height: 3.5rem
    width: 3.5rem
    min-width: 3.5rem
