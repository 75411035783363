@import "styles/variables.sass"
@import "styles/breakpoints.scss"

.root
  display: flex
  justify-content: space-between

.link
  &:hover
    .icon
      transform: scale(1.1)
      path
        fill: $light-yellow
  &:active
    .icon
      transform: scale(1)
      path
        fill: $blue
  &:not(:last-child)
    margin-right: 0

.icon
  width: 1.875rem
  height: auto
  transition: transform $animationTime
  path
    transition: fill $animationTime

.link
  &:not(:last-child)
    margin-right: 0

@include media(">Sphone")
  .root
    justify-content: initial
  .link
    &:not(:last-child)
      margin-right: .5rem

@include media(">Mphone")
  .link
    &:not(:last-child)
      margin-right: 0
