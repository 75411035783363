@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.root
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-bottom: 0.5rem

.innerModal
  align-items: flex-start
  padding: .75rem 1.5rem

.address
  margin-bottom: 1.438rem
  font-weight: bold
  font-size: .875rem
  word-break: break-all
  text-align: left

.iconsWrapper, .icon
  margin-left: 1rem

.iconsWrapper
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden

.clipboard, .arrow
  cursor: pointer
  transition: transform $animationTime

.arrow
  transform: translateY(-150%)
  > path
    fill: $light-blue

.clipboard
  transform: translateY(-50%)

.success
  .arrow
    transform: translateY(50%)
  .clipboard
    transform: translateY(150%)

.options
  display: flex
  flex-direction: column
  margin: 0 auto

.logout
  position: absolute
  top: .3rem
  right: 2.375rem
  margin: 0 auto -.5rem
  font-size: .875rem
  color: $light-yellow
  transition: $animationTime
  &:hover
    text-shadow: $light-yellow-shadow-button
  &:active
    color: $white
    text-shadow: $white-shadow-button

.button
  display: flex
  justify-content: center
  align-items: center
  color: $blue-neutral
  line-height: 1.083rem
  transition: $animationTime
  &:hover
    color: $light-yellow
    path
      fill: $light-yellow
  &:active
    color: $white
    path
      fill: $white
  &:first-child
    margin-bottom: .5rem
  &:last-child
    margin-bottom: -.25rem

.button
  path
    transition: fill $animationTime

.transactions
  margin-top: 1.5rem

@include media('>Mphone')
  .innerModal
    padding: 2rem 3.5rem
  .address
    margin-bottom: 1.5rem
    font-size: 1rem
  .options
    flex-direction: row
    margin-bottom: 1rem
  .button
    &:not(:last-child)
      margin-right: 2.5rem
      margin-bottom: 0
  .logout
    top: 2.125rem
    right: 2.625rem
    margin-top: -.5rem
  .transactions
    margin-top: 2.5rem
