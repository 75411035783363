@import 'styles/breakpoints.scss'

.heading
  margin-bottom: 1rem

@include media('>Mphone')
  .root
    margin-bottom: 3rem
  .heading
    margin-bottom: 2rem
