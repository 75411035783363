@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.root
  display: flex
  flex-direction: column

.graphWrapper
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  padding: 1.5rem 2rem
  background: linear-gradient(90deg, rgba(0, 205, 238, 0.08) 0%, rgba(0, 0, 0, 0) 100%)
  &::after
    content: ''
    position: absolute
    left: 0
    top: 0
    bottom: 0
    width: .063rem
    background-color: $light-blue
    box-shadow: $blue-shadow-button

.pieGraph
  display: flex
  justify-content: center
  align-items: center
  border-radius: 50%
  margin-bottom: 1rem
  overflow: hidden

.pieLoading
  width: 9rem
  height: 9rem

.stat
  color: $light-blue
  text-align: center
  &:not(:last-child)
    margin-bottom: 1rem
  &:first-child
    .statTitle
      text-shadow: $blue-shadow-button
    .statValue
      text-shadow: $blue-shadow
  &:last-child
    color: $light-yellow
    .statTitle
      text-shadow: $light-yellow-shadow-button
    .statValue
      text-shadow: $light-yellow-shadow

.statTitle
  font-size: .75rem
  margin-bottom: .5rem

.statValue
  font-weight: 700
  &:not(:last-child)
    margin-bottom: .5rem

.detailsWrapper
  flex: 1
  margin-bottom: 2rem
  order: -1

.detailsTitle
  margin-bottom: 1rem

.list
  display: flex
  flex-direction: column

.item
  height: 1.21rem
  &:not(:last-child)
    margin-bottom: 1rem

@include media('>Mphone')
  .graphWrapper
    justify-content: space-between
    margin-bottom: 2rem
  .pieGraph
    margin-right: 0rem
  .pieLoading
    margin-left: 0
  .statTitle
    font-size: 1rem
  .detailsTitle
    margin-bottom: 2rem
  .item
    &:not(:last-child)
      margin-bottom: 1.5rem

@include media('>Lphone')
  .graphWrapper
    flex-direction: row
  .pieGraph
    width: 16rem
    height: 16rem
    margin-bottom: 0
  .pieLoading
    width: 14rem
    height: 14rem
    margin-left: 1rem
  .stat
    font-size: 1.5rem
    text-align: left
    &:not(:last-child)
      margin-bottom: 4rem
  .statTitle
    font-size: 1.5rem
    margin-bottom: 1.5rem
  .statValue
    &:not(:last-child)
      margin-bottom: 1rem
  .detailsWrapper
    margin-bottom: 0
    order: 1
  .detailsTitle
    margin-bottom: 3rem

@include media('>tablet')
  .graphWrapper
    justify-content: center
  .pieGraph, .pieLoading
    margin-right: 5rem

@include media('>Ltablet')
  .root
    flex-direction: row
    justify-content: space-between
  .graphWrapper
    justify-content: flex-start
    margin-bottom: 0
    margin-right: 2rem
    padding: 3rem 4rem
  .pieLoading
    margin-right: 6rem

@include media('>Sdesktop')
  .graphWrapper
    flex: 1.5
  .pieGraph
    margin-right: 7.5rem
  .pieLoading
    margin-right: 8.5rem
