@import 'styles/breakpoints.scss'
@import 'styles/variables.sass'

.alert
  display: block
  position: relative

  margin: 2rem 0
  text-wrap: balance
  border: 1px solid $light-yellow
  padding: 2rem 2rem 2rem 4rem
  background: $yellow-light-gradient
  border-radius: 1rem
  box-shadow: $light-yellow-shadow
  &:hover
    box-shadow: $light-yellow-shadow, 0px 0px 4px $light-yellow

    &:after
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      border-radius: 1rem
      background: $yellow-light-gradient
      box-shadow: $light-yellow-shadow
  
  .alertTitle
    font-size: 1.5rem
  .link
    color: $blue
    text-decoration: underline
    font-weight: 700

.container
  min-height: calc(100vh - 14rem) // 100vh - (4rem header + 10rem footer)
  margin-top: 4rem // 2rem + 4rem header
  padding-top: 2rem

@include media('>phone')
  .container
    margin-top: 3.5rem

@include media('>Ltablet')
  .container
    margin-top: 4rem
    padding-top: 4rem
