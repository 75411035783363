@import "styles/variables.sass"
@import "styles/breakpoints.scss"

.overlay
  position: fixed
  top: 0
  right: 0
  left: 0
  bottom: 0
  z-index: 100
  background: linear-gradient(180deg, rgba(#00091A, .8) 0%, rgba($black, .8) 100%)
  cursor: pointer
  display: flex
  align-items: flex-start
  justify-content: center
  max-height: 100vh
  overflow-y: auto
  overflow-x: hidden

.portal
  position: relative
  transition: none
  opacity: 1
  z-index: 100

.wrapper
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  min-height: 100%
  padding: 1rem 0
  box-sizing: border-box

.root
  outline: none
  height: 100%
  width: 100%
  min-width: initial
  margin: 0 .5rem
  text-align: center

.inner
  position: relative
  padding: .75rem 3rem
  cursor: default
  background-color: rgba($dark-blue, .9)
  border-radius: .5rem
  color: $white
  display: flex
  flex-direction: column
  align-items: center
  box-sizing: content-box
  // TODO: Research property
  // min-width: 16.25rem
  max-width: 21rem
  width: 100%
  min-width: 0

.closeButton
  position: absolute
  top: .875rem
  right: 1.375rem

.closeButton
  path
    transition: fill $animationTime

.closeIcon
  width: .75rem
  height: auto

.primary
  &:hover
    path
      fill: $blue-neutral

.secondary
  &:hover
    path
      fill: $light-yellow

@include media('>Mphone')
  .closeIcon
    width: 1.125rem
    height: auto
  .closeButton
    top: 2rem
    right: 1rem
  .inner
    width: initial
    max-width: none
    padding: 2rem 3.5rem
    border-radius: .5rem
