@import "styles/variables.sass"
@import "styles/breakpoints.scss"

.button
  justify-content: center
  font-size: .75rem

.connectDesktop
  display: none

.transactions
  position: relative
  display: flex
  align-items: center
  .buttonText
    margin-right: 1rem

@include media(">Sphone")
  .connectDesktop
    display: inline

@include media(">Ltablet")
  .button
    font-size: .875rem
