@import 'styles/variables.sass'

.root
  position: relative
  display: flex
  justify-content: center
  color: $white

.animation
  width: 1rem
  height: 1rem
  border: .063rem solid $white
  border-bottom-color: transparent
  border-radius: 50%
  animation: rotate 1.5s infinite
.blue
  border-color: $light-blue
  border-bottom-color: transparent
.yellow
  border-color: $light-yellow
  border-bottom-color: transparent

.transparent
  .animation
    border-color: rgba($white, .75)
    border-bottom-color: transparent
  .dots
    color: rgba($white, .75)

.dots
  position: absolute
  top: 0.25rem
  line-height: .063rem
  letter-spacing: 0rem
  font-size: .75rem

@keyframes rotate
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
