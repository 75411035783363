@import "styles/variables.sass"

.root
  position: relative
  display: flex
  align-items: center
  width: 100%
  height: .75rem
  border-radius: 1rem

.layout
  position: absolute
  height: 0.625rem
  width: 0.625rem
  left: 0.063rem
  border-radius: 1rem

.primary
  background-color: rgba($blue-neutral, .3)
  .layout
    background-color: $blue-neutral
    box-shadow: $blue-shadow

.secondary
  background-color: rgba($light-yellow, .3)
  .layout
    background-color: $light-yellow
    box-shadow: $light-yellow-shadow

.warning
  background-color: rgba($light-red, .3)
  .layout
    background-color: $light-red
    box-shadow: $red-shadow