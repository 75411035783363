@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.root
  width: 100%

.head
  position: relative
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  margin-bottom: 1.25rem
  padding-bottom: .5rem
  border-bottom: .063rem solid rgba($white, .5)

.title
  font-size: .875rem

.wrapper
  position: relative
  max-height: 19.5rem
  overflow: hidden
  overflow-y: auto
  padding-right: .5rem
  margin-right: -.5rem

.innerModal
  display: flex
  justify-content: center
  width: 100%
  padding-right: 0

.modalInnerWrapper
  display: flex
  align-items: center
  justify-content: center

.clearHistoryIcon
  position: absolute
  right: 0
  cursor: pointer

.icon
  width: 1rem
  height: 1rem
  path
    transition: fill $animationTime
  &:hover
    > path
      fill: $blue-neutral

.confirmationButton
  &:not(:last-child)
    margin-right: .5rem

.transaction
  display: flex
  justify-content: space-between
  &:not(:last-child)
    margin-bottom: 1.5rem

.info
  display: flex
  justify-content: space-between
  flex: 1

.statusText
  text-transform: capitalize

.status
  margin-right: .5rem

.info
  display: flex
  flex-direction: column
  align-items: flex-start
  font-size: .875rem

.type
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  margin-bottom: .5rem

.amount
  margin-bottom: .25rem
  font-size: 1rem
  font-weight: 700

.footer
  display: flex
  justify-content: space-between
  align-items: flex-end
  width: 100%

.link
  > path
    transition: fill $animationTime

.linkButton
  &:hover
    .link
      > path
        fill: $white

.timestamp
  font-size: .75rem

// custom scroll  
.wrapper::-webkit-scrollbar-track
  border-radius: .625rem
  box-shadow: inset 0 0 .375rem rgba($black, .3)

.wrapper::-webkit-scrollbar
  width: .375rem

.wrapper::-webkit-scrollbar-thumb
  border-radius: .625rem
  box-shadow: inset 0 0 .375rem rgba($black, .3)
  background: $white
  cursor: pointer

// colors 
.blue
  color: $light-blue
  > path
    fill: $light-blue
.yellow
  color: $light-yellow
  > path
    fill: $light-yellow
.red
  color: $light-red
  > path
    fill: $light-red

@include media('>Mphone')
  .title
    font-size: 1rem
  .confirmationButton
    &:not(:last-child)
      margin-right: 1rem
