@import 'styles/variables'
@import 'styles/breakpoints'

.root
  position: relative
  width: 100%
  max-width: 18rem
  padding-bottom: 1rem
  font-size: .75rem
  font-weight: 700
  &:not(:last-child)
    margin-bottom: 1rem
  &::after
    content: ''
    position: absolute
    top: 100%
    width: 100%
    height: .063rem
    background-color: $white

.primary
  color: $blue-neutral
  text-shadow: $blue-shadow
  &::after
    background-color: $blue-neutral
    box-shadow: $blue-shadow-button

.secondary
  color: $light-yellow
  text-shadow: $light-yellow-shadow
  &::after
    background-color: $light-yellow
    box-shadow: $light-yellow-shadow-button

.tertiary
  .title
    text-shadow: $white-shadow
  &::after
    background-color: $white
    box-shadow: $white-shadow-button
    opacity: .5

.arrow
  position: absolute
  top: .5rem
  right: .5rem

.link
  position: absolute
  top: .33rem
  right: 0
  font-size: .875rem
  text-shadow: none

.ReactCollapse
  transition: height 250ms ease-in-out

.dropdown
  padding-bottom: 1rem
  cursor: initial

.preloader
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

.row
  display: flex
  align-items: center
  min-height: 1.5rem
  width: 100%
  &:not(:last-child)
    margin-bottom: .5rem

.title
  width: 6.875rem
  margin-right: 1rem

.value
  font-size: .875rem
  font-weight: 400
  text-shadow: initial
