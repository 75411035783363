@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.wrapper
  display: flex
  align-items: center

.radio
  margin-right: 1rem

.icon
  margin-left: auto
  margin-top: -.25rem

.repayRow
  grid-template-columns: repeat(4, 1fr)

.repayThead
  border-bottom: 1px solid $light-yellow

.borrowRow
  grid-template-columns: 1.25fr repeat(1, 1fr) 1.2fr 1.3fr .5fr

.supplyRow
  grid-template-columns: 1.25fr 1.25fr 1.1fr 1.65fr .5fr

.supplyRow, .borrowRow
  td:nth-last-child(2)
    white-space: nowrap

.ownAssetsRow
  grid-template-columns: 1fr .8fr 1.45fr .75fr .5fr

.bigTableWrapper
  overflow: hidden
  overflow-x: auto

.bigTable
  min-width: 78rem
  width: 100%

.bigTableLiquidate
  min-width: 63.75rem
  width: 100%

.marketsRow
  grid-template-columns: repeat(7, 1fr) .5fr

.marketsDetailsRow
  grid-template-columns: repeat(6, 1fr)

.liquidationRow
  grid-template-columns: 1fr repeat(2, .75fr) repeat(2, .75fr) .5fr

.borrowedAssets
  padding-right: .75rem

.liquidateRow
  grid-template-columns: repeat(5, 1fr)

.link
  font-size: 0.875rem
  text-transform: uppercase

.white
  color: $white

.noShadow
  text-shadow: initial

.blue
  color: $light-blue
  text-shadow: $blue-shadow

.yellow
  color: $light-yellow
  text-shadow: $light-yellow-shadow

.address
  padding-left: 0
  &.white
    color: $white
    &:hover
      text-shadow: $white-shadow-button
  &.noHover
    &:hover
      text-shadow: initial

.amount
  margin-bottom: .25rem

.amountUsd
  font-size: .75rem

.noWrap
  white-space: nowrap

.balance
  padding-right: 2rem

@include media('>Mphone')
  .bigTableLiquidate
    min-width: 78rem
    width: 100%
