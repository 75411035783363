@import "styles/variables.sass"
  
.root
  display: flex
  align-items: center
  padding: .5rem
  width: fit-content
  height: fit-content
  text-transform: capitalize
  text-decoration: underline
  &:hover
    color: $blue
    svg
      path
        fill: $light-blue

.text
  margin-left: .3rem
