@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.root
  display: flex
  flex-direction: column

.detailsWrapper
  margin-bottom: 2rem

.tokenName
  margin-bottom: 1.5rem
  font-size: 1.5rem
  font-weight: 700

.modelTitle
  margin-top: .5rem
  font-size: 1rem

.list
  display: flex
  flex-direction: column

.item
  &:not(:last-child)
    margin-bottom: 1.5rem

.chartWrapper
  position: relative
  height: 300px

@include media('>Mphone')
  .graphWrapper
    justify-content: space-between
  .tokenName
    margin-bottom: 2rem

@include media('>Lphone')
  .graphWrapper
    flex-direction: row

@include media('>Mtablet')
  .root
    flex-direction: row
    justify-content: space-between
  .detailsTitle
    max-width: 17rem
  .chartWrapper
    height: initial
    flex: 2
  .detailsWrapper
    width: 24rem
    margin-right: 4rem
