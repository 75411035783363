@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  max-width: 100%
  min-height: calc(100vh - 16.2rem)
  padding: 0
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba($blue, .7))

.number, .text
  font-weight: bold
  color: $light-blue

.number
  font-size: 5.5rem
  line-height: 6.5rem

.text
  font-size: 1rem

.header
  margin-bottom: 0

@include media('>Mphone')
  .container
    min-height: calc(100vh - 14.4rem)
  .number
    margin-bottom: 1rem
    font-size: 8rem
  .text
    font-size: 1.5rem
