@import 'styles/variables.sass'
@import 'styles/breakpoints.scss'

.inputWrapper
  width: 100%
  margin-bottom: 1.5rem

.container
  display: flex
  justify-content: space-between
  width: 100%
  padding: .5rem 1rem
  border: .063rem solid $white
  border-radius: .25rem
  cursor: text
  transition: border-color $animationTime

.wrapper
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: space-between
  width: 100%
  color: $light-blue

.input
  width: 100%
  font-size: .875rem
  font-weight: bold
  color: $light-blue
  background: transparent
  border: 0
  outline: none
  line-height: 1.185rem
  transition: $animationTime
  &::placeholder
    color: $light-blue
    font-weight: bold
    font-size: 1rem
    transition: color $animationTime
  &:focus::-webkit-input-placeholder
    visibility: hidden
    -moz-appearance: none

.primay, .secondary
  .input
    &:active, &:focus
      color: $white

.exchange
  font-size: .75rem
  text-shadow: none
  transition: color $animationTime
  &.active
    color: $white

.primary, .secondary
  &.error
    border-color: $light-red
    .input, .exchange, .button
      color: $light-red
    .button
      &:hover
        color: $light-red
        text-shadow: $red-shadow
    .input
      &::placeholder
        color: $light-red
    .prettyTez
      path
        fill: $light-red
  &.warning
    border-color: $light-yellow
    .input, .exchange, .button
      color: $light-yellow
    .button
      &:hover
        color: $light-yellow
        text-shadow: $light-yellow-shadow
    .input
      &::placeholder
        color: $light-yellow
    .prettyTez
      path
        fill: $light-yellow

.button
  display: flex
  align-items: center
  justify-content: center
  outline: none
  border: 0
  background: transparent
  font-weight: bold
  font-size: 1rem
  line-height: 1.188rem
  text-transform: uppercase
  color: $white
  transition: color $animationTime
  &:disabled
    color: rgba($white, .4)
    text-shadow: none

.primary
  .button
    &:hover
      color: $light-blue
      text-shadow: $blue-shadow
  .prettyTez
    path
      fill: $light-blue

.root
  .prettyTez
    &.active
      path
        fill: $white

.root
  .prettyTez
    &.error
      path
        fill: $red
    &.warning
      path
        fill: $light-yellow

.secondary
  .button
    &:hover
      color: $light-yellow
      text-shadow: $light-yellow-shadow
  .wrapper, .input
    color: $light-yellow
  .input
    &::placeholder
      color: $light-yellow
  .prettyTez
    path
      fill: $light-yellow

.errorContainer
  display: flex
  align-items: center
  justify-content: center
  height: 0
  width: 100%
  overflow: hidden
  transition: height $animationTime
  background-color: rgba($red, .48)
  box-shadow: $red-shadow
  border-radius: .25rem
  &.error
    height: 1.5rem
    margin-top: 8px
    font-weight: 700

.errorText
  color: $light-red
  text-shadow: none
  font-size: .75rem

.warningContainer
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  overflow: hidden
  transition: max-height $animationTime
  background-color: rgba($light-yellow, .48)
  box-shadow: $light-yellow-shadow-button
  border-radius: .25rem
  margin-top: 8px
  font-weight: 700
  padding: 4px 8px

.warningText
  color: $light-yellow
  text-shadow: none
  font-size: .75rem

.disabled
  border-color: rgba($white, .4)
  cursor: not-allowed
  .input, .exchange
    color: rgba($white, .4)
  .input
    cursor: not-allowed
  .button
    &:hover
      color: rgba($white, .4)
      text-shadow: none
  .prettyTez
    path
      fill: rgba($white, .4)

.prettyTez
  height: 0.75rem
  width: auto
  margin-top: 0.1rem
  margin-left: 0.25rem

@include media('>Mphone')
  .container
    padding: 1rem 1.5rem
    height: 4.5rem
  .input
    height: 1rem
    font-size: 1rem
  .errorText
    font-size: .875rem
  .warningText
    font-size: .875rem
