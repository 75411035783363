.root
  display: inline-flex
  align-items: center

.tezosIcon
  margin-left: .5rem
  margin-top: .25rem
  path
    fill: currentColor

.tooltipContent
  display: inline-flex
  align-items: center
  .tezosIconTooltip
    height: .8rem
    width: auto
    margin-top: .05rem
    margin-left: .25rem