@import 'styles/variables.sass'

.root
  display: flex
  align-items: center
  color: $white
  &:hover
    text-shadow: $white-shadow
  &.loading
    &:hover
      text-shadow: none

.active
  text-shadow: $white-shadow

.logo
  margin-right: 1rem
